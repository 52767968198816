<h3>Player Suspensions</h3>
<div class="flex-col">
  @if (uiState === uiStateForTemplate.ShowData) {
    <button mat-stroked-button
            *hasPermission="PermissionTypes.ModifyPlayerSuspensions"
            data-testid="suspend-player-button"
            class="red-text margin-top-med"
            (click)="openSuspendPlayerDialog()">
      <mat-icon>lock_person</mat-icon>
      Suspend Player
    </button>
    <ag-grid-angular
      id="playerSuspensionsGrid"
      class="ag-theme-quartz margin-top-med large-table"
      data-testid="player-suspensions-grid"
      [tooltipShowDelay]="0"
      rowClass="table-row"
      domLayout='autoHeight'
      overlayNoRowsTemplate="<span>No suspensions for this player</span"
      (gridReady)="onPlayerSuspensionsGridReady($event)"
      (rowClicked)="openPlayerSuspensionRowClick($event)"
      [rowData]="suspensions"
      [pagination]="true"
      [columnDefs]="playerSuspensionsColumnDefs">
    </ag-grid-angular>
  } @else {
    <mat-progress-spinner mode="indeterminate" class="center-margin"></mat-progress-spinner>
  }
</div>
