import {APIService} from "./api.service";
import {map, Observable} from "rxjs";
import {IStandardGameReport} from "../interfaces/IStandardGameReport";
import {Injectable} from "@angular/core";
import {IGetRaffleReportResult} from "../interfaces/reporting/IGetRaffleReportResult";
import {IPlayersAndTicketsPerGameInstance} from "../interfaces/reporting/IPlayersAndTicketsPerGameInstance";
import {IPlayerRetentionReportResult} from "../interfaces/reporting/IPlayerRetentionReportResult";
import {IHourlyTicketSalesPerHourPerGame} from "../interfaces/reporting/IHourlyTicketSalesPerHourPerGame";
import {GameService} from "./game.service";
import {IManualAndAutoPlayTickets} from "../interfaces/reporting/IManualAndAutoPlayTickets";
import {ISalesPerDay} from "../interfaces/reporting/ISalesPerDay";
import {ICharitySalesPerDraw} from "../interfaces/reporting/ICharitySalesPerDraw";
import {IComprehensiveReport} from "../interfaces/reporting/IComprehensiveReport";
import {ILotteryLicenseReport} from "../interfaces/reporting/ILotteryLicenseReport";
import {IGameCharitySupporterBreakdown} from "../interfaces/reporting/IGameCharitySupporterBreakdown";

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  constructor(private gameService: GameService,
              private apiService: APIService) {
  }

  public getStandardGameInstanceReportData(gameInstanceId: string): Observable<IStandardGameReport> {
    let gameId = this.gameService.activeGame().Id;
    return this.apiService.MakeGetRequest<IStandardGameReport>(`report/game-instance-report/for-game/${gameId}/by-id/${gameInstanceId}`).pipe(map(res => res));
  }

  public getRafflingReportData(gameInstanceIdP: string): Observable<IGetRaffleReportResult> {
    return this.apiService.MakeGetRequest<IGetRaffleReportResult>(`game-instance/players-with-tickets/game/${this.gameService.activeGame().Id}/${gameInstanceIdP}`);
  }

  public getPastGamesPlayersAndTickets(gameIdP: string, numberOfGameInstancesP: number): Observable<IPlayersAndTicketsPerGameInstance[]> {
    return this.apiService.MakeGetRequest<IPlayersAndTicketsPerGameInstance[]>(`report/players-and-tickets/${gameIdP}/number-of-games/${numberOfGameInstancesP}`);
  }

  public getComprehensiveGameReportData(gameIdP: string, startDateP: string, endDateP: string): Observable<IComprehensiveReport> {
    return this.apiService.MakeGetRequest<IComprehensiveReport>(`report/comprehensive-report/${gameIdP}/${startDateP}/${endDateP}`);
  }

  public getLotteryLicenseReport(gameIdP: string, startDateP: string, endDateP: string): Observable<ILotteryLicenseReport> {
    return this.apiService.MakeGetRequest<ILotteryLicenseReport>(`report/lottery-license-report/${gameIdP}/${startDateP}/${endDateP}`);
  }

  public fetchSalesPerGameReport(gameIdP: string): Observable<IPlayerRetentionReportResult[]> {
    return this.apiService.MakeGetRequest<IPlayerRetentionReportResult[]>(`report/sales-per-game/${gameIdP}`);
  }

  public fetchGameCharitySupporterBreakdown(gameIdP: string): Observable<IGameCharitySupporterBreakdown[]> {
    return this.apiService.MakeGetRequest<IGameCharitySupporterBreakdown[]>(`report/game-charity-supporters-breakdown/${gameIdP}`);
  }

  public hourlyTicketSalesBreakdown(gameIdP: string): Observable<IHourlyTicketSalesPerHourPerGame[]> {
    return this.apiService.MakeGetRequest<IHourlyTicketSalesPerHourPerGame[]>(`report/hourly-ticket-sales/${gameIdP}`);
  }

  public getManualVsAutoPlayTickets(gameIdP: string, gameInstanceIdP: string): Observable<IManualAndAutoPlayTickets> {
    return this.apiService.MakeGetRequest<IManualAndAutoPlayTickets>(`report/manual-vs-autoplay-tickets/${gameInstanceIdP}/${gameIdP}`);
  }

  public salesPerDay(gameIdP: string, gameInstanceIdP: string): Observable<ISalesPerDay[]> {
    return this.apiService.MakeGetRequest<ISalesPerDay[]>(`report/sales-per-day/${gameInstanceIdP}/${gameIdP}`);
  }

  public topTenCharityContributors(gameIdP: string, gameInstanceIdP: string): Observable<ICharitySalesPerDraw[]> {
    return this.apiService.MakeGetRequest<ICharitySalesPerDraw[]>(`report/top-charity-contributors/${gameInstanceIdP}/${gameIdP}`);
  }
}
