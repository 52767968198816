import {Component} from '@angular/core';
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {IGameInstance} from "../../interfaces/IGameInstance";
import {GameInstanceStatuses} from "../../enum/GameInstanceStatusEnum";
import {GameService} from "../../services/game.service";
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {MatIcon} from "@angular/material/icon";
import {MatButton} from "@angular/material/button";
import {ChooseGameInstanceComponent} from "../../components/choose-game-instance/choose-game-instance.component";
import {MatTabsModule} from "@angular/material/tabs";
import {
  StandardGameReportComponent
} from "../../components/reporting/standard-game-report/standard-game-report.component";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {AuditingReportComponent} from "../../components/reporting/auditing-report/auditing-report.component";
import {SalesPerGameComponent} from "../../components/reporting/sales-per-game/sales-per-game.component";
import {HourlyTicketSalesComponent} from "../../components/reporting/hourly-ticket-sales/hourly-ticket-sales.component";
import {
  DateRangeReportingComponent
} from "../../components/reporting/date-range-reporting/date-range-reporting.component";
import {ColComponent, ContainerComponent, RowComponent} from "@coreui/angular";
import {
  GameCharitySupporterBreakdownComponent
} from "../../components/reporting/game-charity-supporter-breakdown/game-charity-supporter-breakdown.component";

@Component({
  selector: 'app-reports',
  standalone: true,
  templateUrl: './reports.component.html',
  imports: [
    MatIcon,
    MatButton,
    MatStepper,
    MatStep,
    MatStepLabel,
    ChooseGameInstanceComponent,
    MatTabsModule,
    StandardGameReportComponent,
    HasPermissionDirective,
    AuditingReportComponent,
    SalesPerGameComponent,
    HourlyTicketSalesComponent,
    DateRangeReportingComponent,
    ContainerComponent,
    RowComponent,
    ColComponent,
    GameCharitySupporterBreakdownComponent
  ],
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  public permissionTypes = PermissionTypes;
  public activeGame: IGameQueryResult | undefined = this.gameService.activeGame();
  public chosenGameInstance: IGameInstance | null = null;
  public readonly DATE_RANGE_REPORTS = 1;
  public readonly REPORTING_PER_GAME = 2;
  public readonly CHARITY_REPORT = 3;
  public chosenReportingView: number | null = null;
  public gameInstanceStatusesForTemplate = GameInstanceStatuses;
  selectedTab: number = 0;

  constructor(private gameService: GameService) {
  }

  public updateChosenGameInstance(gameInstance: IGameInstance, stepperP: MatStepper) {
    this.chosenGameInstance = gameInstance;
    stepperP.next();
  }

  public resetActiveGameInstance(stepperP: MatStepper) {
    this.chosenGameInstance = null;
    stepperP.previous();
  }

  public updateReportingScreen(reportingViewP: number | null) {
    this.chosenReportingView = reportingViewP;
  }
}
