import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from "@angular/material/datepicker";
import {AsyncPipe, NgClass} from "@angular/common";
import {MatOption, MatSelect} from "@angular/material/select";
import {AgGridAngular} from "ag-grid-angular";
import {MatTooltip} from "@angular/material/tooltip";
import {ISupportRequestPagedResponse} from "../../../interfaces/player/ISupportRequestPagedResponse";
import {map, Observable, of} from "rxjs";
import {ISupportRequest} from "../../../interfaces/player/ISupportRequest";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {GridApi, GridReadyEvent, RowClickedEvent} from "ag-grid-community";
import {SupportRequestService} from "../../../services/support-request.service";
import {DateService} from "../../../services/date.service";
import {GameService} from "../../../services/game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialog} from "@angular/material/dialog";
import {SupportDeskColDefs} from "../support-desk-column-defs";
import {ActiveUserService} from "../../../services/active-user.service";
import {ISupportRequestFilter} from "../../../interfaces/ISupportRequestFilter";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {
  SupportRequestDetailsComponent
} from "../../../components/dialogs/support-request-details/support-request-details.component";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";

@Component({
  selector: 'app-support-requests-tab',
  standalone: true,
  imports: [ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    MatIcon,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatEndDate,
    MatStartDate,
    MatHint,
    MatSuffix,
    AsyncPipe,
    MatSelect,
    MatOption,
    AgGridAngular,
    NgClass,
    MatIconButton,
    MatTooltip],
  templateUrl: './support-requests-tab.component.html',
  styleUrl: './support-requests-tab.component.scss'
})
export class SupportRequestsTabComponent {
  @Input() set setGameId(gameIdP: string | null) {
    if (gameIdP !== null) {
      this.shouldDisplayGameIdControl = false;
      this.gameIdControl.setValue(gameIdP);
      this.gameIdControl.disable();
    }
  }

  public shouldDisplayGameIdControl = true;

  public pagedSupportRequestResponse: ISupportRequestPagedResponse | null = null;
  public supportRequests$: Observable<ISupportRequest[]> = this.supportRequestService.supportRequests$.pipe(map((response) => {
    this.pagedSupportRequestResponse = response;
    this.updatePaginator();
    return response.Data;
  }));
  public uiState = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public gridApi!: GridApi;
  public gameAdmins: IAdminQueryResult[] = [];

  public columnDefs = this.supportDeskColumnDefs.supportRequestColDefs;

  public errorTypes = this.supportDeskColumnDefs.errorTypes;

  public playerNameControl = new FormControl<string | null>("");
  public playerEmailControl = new FormControl<string | null>("");
  public playerIdControl = new FormControl<string | null>("");
  public gameIdControl = new FormControl<string | null>("");
  public errorTypeControl = new FormControl<number | null>(null);
  public assigneeControl = new FormControl<string | null>("");
  public escalatedControl = new FormControl<boolean | null>(null);
  public fromDateControl = new FormControl(this.dateService.twoWeeksAgo());
  public toDateControl = new FormControl(this.dateService.tomorrow());
  public games$: Observable<IGameQueryResult[]> = of([]);

  public filterForm = new FormGroup({
    playerName: this.playerNameControl,
    playerEmail: this.playerEmailControl,
    playerId: this.playerIdControl,
    gameId: this.gameIdControl,
    errorType: this.errorTypeControl,
    assignee: this.assigneeControl,
    escalated: this.escalatedControl,
    fromDate: this.fromDateControl,
    toDate: this.toDateControl
  });
  fromNumber: number = 0;
  toNumber: number = 0;
  totalRecords: number = 0;
  pageNumber: number = 0;
  totalPages: number = 0;

  constructor(private supportRequestService: SupportRequestService,
              private dateService: DateService,
              private gameService: GameService,
              private errorHandlingService: ErrorHandlingService,
              private matDialog: MatDialog,
              private supportDeskColumnDefs: SupportDeskColDefs,
              private activeUserService: ActiveUserService) {
  }

  ngOnInit() {
    if (this.shouldDisplayGameIdControl) {
      this.games$ = this.gameService.getGamesForUser(this.activeUserService.activeUser().Id);
    }
    this.fetchAdmins();
    this.getPaginatedResponse(1);
  }

  fetchAdmins() {
    this.uiState = UIStateEnum.ShowLoading;
    this.supportRequestService.fetchAdminsToAssignToSupportRequest().subscribe({
      next: (result) => {
        this.uiState = UIStateEnum.ShowData;
        this.gameAdmins = result;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage("Failed to fetch admins");
      }
    })
  }

  onFormSubmit() {
    this.getPaginatedResponse(1);
  }

  onResetClick() {
    this.playerNameControl.setValue("");
    this.playerEmailControl.setValue("");
    this.playerIdControl.setValue("");
    if (this.shouldDisplayGameIdControl) {
      this.gameIdControl.setValue("");
    }
    this.assigneeControl.setValue("");
    this.errorTypeControl.setValue(null);
    this.escalatedControl.setValue(null);
    this.fromDateControl.setValue(this.dateService.twoWeeksAgo());
    this.toDateControl.setValue(this.dateService.tomorrow());

    this.getPaginatedResponse(1);
  }

  getPaginatedResponse(pageNumberP: number) {
    let filter: ISupportRequestFilter = {
      PlayerName: this.playerNameControl.value!,
      PlayerEmail: this.playerEmailControl.value!,
      PlayerId: this.playerIdControl.value!,
      GameId: this.gameIdControl.value!,
      ErrorType: this.errorTypeControl.value!,
      Assignee: this.assigneeControl.value!,
      FromDate: this.fromDateControl.value!,
      ToDate: this.toDateControl.value!,
      Escalated: this.escalatedControl.value!,
      PageNumber: pageNumberP,
      PageSize: 15
    }

    this.uiState = UIStateEnum.ShowLoading;

    this.supportRequestService.getSupportRequests(filter).subscribe({
      next: (response) => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onRowClicked(paramsP: RowClickedEvent<ISupportRequest>) {
    this.matDialog.open(SupportRequestDetailsComponent, {
      data: paramsP.data,
      width: "650px"
    }).afterClosed().subscribe({
      next: () => {
        this.getPaginatedResponse(1);
      },
    });
  }

  onGridReady(paramsP: GridReadyEvent<ISupportRequest>) {
    this.gridApi = paramsP.api;
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: 90
    });
  }

  updatePaginator() {
    this.pageNumber = this.pagedSupportRequestResponse!.PageNumber;
    this.totalPages = this.pagedSupportRequestResponse!.TotalPages;
    this.totalRecords = this.pagedSupportRequestResponse!.TotalRecords;
    let toNumber = this.pageNumber * this.pagedSupportRequestResponse!.PageSize;
    if (toNumber > this.totalRecords) {
      this.toNumber = this.totalRecords;
      this.fromNumber = (toNumber - this.pagedSupportRequestResponse!.PageSize) + 1;
    } else {
      this.toNumber = toNumber;
      this.fromNumber = (this.toNumber - this.pagedSupportRequestResponse!.PageSize) + 1;
    }
  }

  onBtFirst() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(1);
  }

  onBtLast() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pagedSupportRequestResponse?.TotalPages!);
  }

  onBtNext() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber + 1);
  }

  onBtPrevious() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber - 1);
  }

  fillWithActiveUserId() {
    this.assigneeControl.setValue(this.activeUserService.activeUser().Id);
  }
}
