import {Injectable} from "@angular/core";
import {APIService} from "./api.service";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import {IPlayer} from "../interfaces/player/IPlayer";
import {IPlayerSearchParams} from "../interfaces/player/IPlayerSearchParams";
import {IPlayerPerGameQueryResult} from "../interfaces/player/IPlayerPerGameQueryResult";
import {IApiResponseBase} from "../interfaces/IApiResponseBase";
import {IDeactivatePlayerNumbers} from "../interfaces/player/IDeactivatePlayerNumbers";
import {IReactivatePlayerNumbers} from "../interfaces/player/IReactivatePlayerNumbers";
import {IPlayerPaymentTransactions} from "../interfaces/player/IPlayerPaymentTransactions";
import {HttpParams} from "@angular/common/http";
import {IDeactivatePlayer} from "../interfaces/player/IDeactivatePlayer";
import {IReactivatePlayer} from "../interfaces/player/IReactivatePlayer";
import {IAdminUpdatePlayerBasicInfo} from "../interfaces/player/IAdminUpdatePlayerBasicInfo";
import {IPlayerNumbersForGameQueryResult} from "../interfaces/player/IPlayerNumbersForGameQueryResult";
import {createStore} from "@ngneat/elf";
import {getActiveEntity, setEntities, withActiveId, withEntities} from "@ngneat/elf-entities";
import {tap} from "rxjs/operators";
import {ISuspendPlayer} from "../interfaces/player/ISuspendPlayer";
import {IResolvePlayerSuspension} from "../interfaces/player/IResolvePlayerSuspension";
import {IPlayerSuspensionQueryResult} from "../interfaces/player/IPlayerSuspensionQueryResult";
import {IDialogGenericData} from "../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../components/dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {DialogFunctionService} from "./dialog-function.service";
import {MatDialog} from "@angular/material/dialog";
import {IGeneralPlayerInfo} from "../interfaces/player/IGeneralPlayerInfo";
import {IPlayerCharityPledge} from "../interfaces/player/IPlayerCharityPledge";
import {IPlayerUnsubscribeResult} from "../interfaces/player/IPlayerUnsubscribeResult";
import {IRemoveUnsubscribesForPlayer} from "../interfaces/player/IRemoveUnsubscribesForPlayer";
import {ILastFiveFailedEmailsQueryResult} from "../interfaces/player/ILastFiveFailedEmailsQueryResult";
import {ISuspendPlayerFromCausable} from "../interfaces/player/ISuspendPlayerFromCausable";
import {IResolvePlayerCausableSuspension} from "../interfaces/player/IResolvePlayerCausableSuspension";

@Injectable({
  providedIn: 'root'
})
export class PlayersService {

  private playerStore = createStore(
    {name: "player-state"},
    withEntities<IPlayerPerGameQueryResult, "Id">(),
    withActiveId()
  );

  public fetchUpdatedTicketNumbersSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public fetchUpdatedTicketNumbersSubject$: Observable<boolean> = this.fetchUpdatedTicketNumbersSubject.asObservable();

  public fetchUpdatedSuspensionsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public fetchUpdatedSuspensions$: Observable<boolean> = this.fetchUpdatedSuspensionsSubject.asObservable();

  constructor(private apiService: APIService,
              private matDialog: MatDialog,
              private dialogFunctionService: DialogFunctionService) {
  }

  // deprecated and is going away
  public getPlayerGameInfo(playerIdP: string, gameIdP: string): Observable<IPlayerPerGameQueryResult> {
    return this.apiService.MakeGetRequest<IPlayerPerGameQueryResult>(`player/${playerIdP}/for-game/${gameIdP}`)
      .pipe(tap(res => this.playerStore.update(setEntities([res]))));
  }

  public getGeneralPlayerData(playerIdP: string, adminIdP: string): Observable<IGeneralPlayerInfo> {
    return this.apiService.MakeGetRequest<IGeneralPlayerInfo>(`player/general-info/${playerIdP}/${adminIdP}`);
  }

  public updateBasicPlayerInfo(commandP: IAdminUpdatePlayerBasicInfo): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player/admin-update/basic-info`, commandP);
  }

  public getPlayerNumbersByGameId(playerIdP: string, gameIdP: string): Observable<IPlayerNumbersForGameQueryResult> {
    return this.apiService.MakeGetRequest<IPlayerNumbersForGameQueryResult>(`player-number/by-player/${playerIdP}/for-game/${gameIdP}`);
  }

  public getPlayerCharityPledgesByGameId(playerIdP: string, gameIdP: string): Observable<IPlayerCharityPledge[]> {
    return this.apiService.MakeGetRequest<IPlayerCharityPledge[]>(`player/pledges/${playerIdP}/${gameIdP}`);
  }

  public getPlayerTransactionsByGameId(playerIdP: string, gameIdP: string): Observable<IPlayerPaymentTransactions[]> {
    return this.apiService.MakeGetRequest<{
      Transactions: IPlayerPaymentTransactions[]
    }>(`player/transactions/${playerIdP}/${gameIdP}`)
      .pipe(map((res) => res.Transactions));
  }

  public deactivatePlayerNumbersByGroup(deactivateCommandP: IDeactivatePlayerNumbers): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player-number/deactivate-numbers`, deactivateCommandP);
  }

  public deactivatePlayer(deactivatePlayerCommandP: IDeactivatePlayer): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player/deactivate`, deactivatePlayerCommandP);
  }

  public onDeactivatePlayerClick(playerInfo: IPlayerPerGameQueryResult) {
    this.dialogFunctionService.setCallback(() => {
      return this.deactivatePlayer({
        playerId: playerInfo?.Id!
      })
    });

    if (playerInfo) {
      let deactivatePlayerDialogData: IDialogGenericData = {
        title: 'Confirm Player Deactivation',
        message: `Please confirm that you would like to deactivate the following player`,
        playerData: {
          firstName: playerInfo.FirstName,
          email: playerInfo.Email,
          lastName: playerInfo.LastName
        },
        dataCyTag: 'confirm-deactivate-player-button',
        successMessage: 'Successfully deactivated player',
        extraDetails: 'Deactivating a player will remove all of their numbers from all games, except any numbers already played for for any active games. The user will no longer have access to any games.'
      };

      const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
        data: deactivatePlayerDialogData,
        width: '650px'
      });

      dialogRef.afterClosed().subscribe(
        {
          next: (updateSuccessful: boolean) => {
            if (updateSuccessful) {
            }
            return of(null);
          }
        })
    }
  }

  public reactivatePlayer(reactivatePlayerCommandP: IReactivatePlayer): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player/reactivate`, reactivatePlayerCommandP);
  }

  public activatePlayerNumbers(reactivateCommandP: IReactivatePlayerNumbers): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player-number/reactivate-numbers`, reactivateCommandP);
  }

  public getPlayersBySearchParams(paramsP?: IPlayerSearchParams) {
    let queryParams: HttpParams = new HttpParams();

    if (paramsP?.gameId) {
      queryParams = queryParams.append('gameId', paramsP?.gameId)
    }

    if (paramsP?.firstName) {
      queryParams = queryParams.append('firstName', paramsP?.firstName)
    }

    if (paramsP?.lastName) {
      queryParams = queryParams.append('lastName', paramsP?.lastName)
    }

    if (paramsP?.email) {
      queryParams = queryParams.append('email', paramsP?.email)
    }

    if (paramsP?.playerId) {
      queryParams = queryParams.append('playerId', paramsP?.playerId)
    }

    if (paramsP?.phoneNumber) {
      queryParams = queryParams.append('phoneNumber', paramsP?.phoneNumber)
    }

    return this.apiService.MakeGetRequest<{ Players: IPlayer[] }>('player/search', queryParams)
      .pipe(map((res) => {
        return res.Players
      }));
  }

  public suspendPlayer(suspendPlayerP: ISuspendPlayer) {
    return this.apiService.MakePostRequest<ISuspendPlayer>("player/suspend", suspendPlayerP);
  }

  public resolvePlayerSuspension(resolveSuspensionP: IResolvePlayerSuspension) {
    return this.apiService.MakePostRequest<IResolvePlayerSuspension>("player/resolve-suspension", resolveSuspensionP);
  }

  public getPlayerSuspensionsForGame(playerIdP: string, gameIdP: string) {
    return this.apiService.MakeGetRequest<IPlayerSuspensionQueryResult>(`player/suspensions/all-for-player/${playerIdP}/in-game/${gameIdP}`);
  }

  public getUnsubscribesForPlayer(emailP: string) {
    return this.apiService.MakeGetRequest<IPlayerUnsubscribeResult>(`player/unsubscribes/for-player/${emailP}`);
  }

  public deleteUnsubscribesForPlayer(commandP: IRemoveUnsubscribesForPlayer) {
    return this.apiService.MakePostRequest("player/remove-unsubscribes-for-player", commandP);
  }

  public getLastFiveFailedEmailsForPlayer(emailP: string) {
    return this.apiService.MakeGetRequest<ILastFiveFailedEmailsQueryResult>(`player/last-five-failed-emails/for-player/${emailP}`);
  }

  public getActivePlayer() {
    return this.playerStore.query(getActiveEntity());
  }

  public getAllPlayerSuspensions(playerIdP: string) {
    return this.apiService.MakeGetRequest<IPlayerSuspensionQueryResult>(`player/suspensions/all-for-player/${playerIdP}`);
  }

  public resolvePlayerCausableSuspension(commandP: IResolvePlayerCausableSuspension) {
    return this.apiService.MakePostRequest("player/resolve-player-causable-suspension", commandP);
  }

  public suspendPlayerFromCausable(commandP: ISuspendPlayerFromCausable) {
    return this.apiService.MakePostRequest("player/suspend-from-causable", commandP);
  }
}
