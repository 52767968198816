import {Injectable} from "@angular/core";
import {APIService} from "./api.service";
import {BehaviorSubject, Observable} from "rxjs";
import {IInviteGameAdminUserRequest} from "../interfaces/IInviteGameAdminUserRequest";
import {IGameQueryResult} from "../interfaces/IGameQueryResult";
import {IAllAdminsForGameResults} from "../interfaces/IAllAdminsForGameResults";
import {IDeactivateAdminUserCommand} from "../interfaces/IDeactivateAdminUserCommand";
import {IReactivateAdminUserCommand} from "../interfaces/IReactivateAdminUserCommand";
import {MatDialog} from "@angular/material/dialog";
import {IApiResponseBase} from "../interfaces/IApiResponseBase";
import {ValidateAdminEmailInviteResult} from "../interfaces/ICheckIfUserExistsResult";
import {ISendGameAdminEmailInviteResponse} from "../interfaces/ISendGameAdminEmailInviteResponse";
import {IGenericAdminUserRequest} from "../interfaces/IGenericAdminUserRequest";
import {IAdmin} from "../interfaces/IAdmin";
import {ISendPasswordRecoveryEmailRequest} from "../interfaces/ISendPasswordRecoveryEmailRequest";
import {IUpdateAdminPasswordRequest} from "../interfaces/IUpdateAdminPasswordRequest";
import {ISendUpdateEmailAddressEmail} from "../interfaces/ISendUpdateEmailAddressEmail";
import {IAdminQueryResult} from "../interfaces/IAdminQueryResult";
import {IResendAdminInviteCommand} from "../interfaces/IResendAdminInviteCommand";
import {IDialogGenericData} from "../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../components/dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {DialogFunctionService} from "./dialog-function.service";
import {GameService} from "./game.service";
import {IInviteCharityAdminUserRequest} from "../interfaces/IInviteCharityAdminUserRequest";
import {ISendCharityAdminEmailInviteResponse} from "../interfaces/ISendCharityAdminEmailInviteResponse";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public getUpdatedAdminUsersBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public getUpdatedAdminUsersBehaviorSubject$: Observable<boolean> = this.getUpdatedAdminUsersBehaviorSubject.asObservable();

  constructor(private apiService: APIService,
              private gameService: GameService,
              private dialogFunctionService: DialogFunctionService,
              private matDialog: MatDialog) {
  }

  private openConfirmationDialog(
    adminUserP: IAdmin,
    dialogTitle: string,
    dialogMessage: string,
    successMessage: string,
    dataCyTag: string,
    action: () => Observable<IApiResponseBase>
  ) {
    this.dialogFunctionService.setCallback(() => action());

    const dialogData: IDialogGenericData = {
      title: dialogTitle,
      message: `${dialogMessage}`,
      playerData: {
        firstName: adminUserP.FirstName,
        email: adminUserP.Email,
        lastName: adminUserP.LastName
      },
      dataCyTag: dataCyTag,
      successMessage: successMessage
    };

    const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
      data: dialogData,
      width: '650px'
    });

    dialogRef.afterClosed().subscribe(updateSuccessful => {
      if (updateSuccessful) {
        this.getUpdatedAdminUsersBehaviorSubject.next(true);
      }
    });
  }

  public onDeactivateUserClick(adminUserP: IAdmin) {
    this.openConfirmationDialog(
      adminUserP,
      'Confirm User Deactivation',
      'Please confirm that you would like to deactivate the following user:',
      'Successfully deactivated user',
      'confirm-deactivate-user-button',
      () => this.deactivateAdminUser({targetAdminId: adminUserP.Id!, gameId: this.gameService.activeGame().Id})
    );
  }

  public onReactivateUserClick(adminUserP: IAdmin) {
    this.openConfirmationDialog(
      adminUserP,
      'Confirm User Reactivation',
      'Please confirm that you would like to reactivate the following user:',
      'Successfully reactivated user',
      'confirm-reactivate-user-button',
      () => this.reactivateAdminUser({targetAdminId: adminUserP.Id!, gameId: this.gameService.activeGame().Id})
    );
  }

  public getAllGameAdminUsers(activeGameP: IGameQueryResult): Observable<IAllAdminsForGameResults> {
    return this.apiService.MakeGetRequest<IAllAdminsForGameResults>(`admin-user/game/${activeGameP.Id}`);
  }

  public getAllAdminsWithHandleEscalatedRequestPermissions(): Observable<IAdminQueryResult[]> {
    return this.apiService.MakeGetRequest<IAdminQueryResult[]>("admin-user/all-admins-with-escalated-request-permissions");
  }

  public getAllGameAdminsWithHandleEscalatedRequestPermissions(gameIdP: string): Observable<IAdminQueryResult[]> {
    return this.apiService.MakeGetRequest<IAdminQueryResult[]>(`admin-user/admins-with-escalated-request-permissions/for-game/${gameIdP}`);
  }

  public sendNewGameAdminUserInvite(inviteRequest: IInviteGameAdminUserRequest): Observable<ISendGameAdminEmailInviteResponse> {
    return this.apiService.MakePostRequest<any>(`admin-user/send-game-verification-email`, inviteRequest);
  }

  public sendNewCharityAdminUserInvite(inviteRequest: IInviteCharityAdminUserRequest): Observable<ISendCharityAdminEmailInviteResponse> {
    return this.apiService.MakePostRequest<any>(`admin-user/send-charity-verification-email`, inviteRequest);
  }

  public sendPasswordRecoveryEmail(sendPasswordRecoveryEmailRequestP: ISendPasswordRecoveryEmailRequest): Observable<IApiResponseBase> {
    return this.apiService.MakePostRequest<any>(`send-admin-password-recovery-email`, sendPasswordRecoveryEmailRequestP);
  }

  public updateAdminPassword(updateAdminPasswordRequestP: IUpdateAdminPasswordRequest): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<any>(`admin-user/update-password`, updateAdminPasswordRequestP);
  }

  public sendUpdateAdminEmailAddressEmail(updateAdminEmailRequestP: ISendUpdateEmailAddressEmail): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<any>(`admin-user/send-admin-update-email-email`, updateAdminEmailRequestP);
  }

  public sendCausableAdminUserInvite(inviteRequest: IGenericAdminUserRequest): Observable<ISendGameAdminEmailInviteResponse> {
    return this.apiService.MakePostRequest<any>(`admin-user/send-causable-admin-verification-email`, inviteRequest);
  }

  public deactivateAdminUser(deactivateCommandP: IDeactivateAdminUserCommand): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<any>(`admin-user/deactivate`, deactivateCommandP);
  }

  public resendAdminInvite(resendInviteCommandP: IResendAdminInviteCommand): Observable<IApiResponseBase> {
    return this.apiService.MakePostRequest<any>(`admin-user/resend-admin-invite`, resendInviteCommandP);
  }

  public reactivateAdminUser(reactivateCommandP: IReactivateAdminUserCommand): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<any>(`admin-user/reactivate`, reactivateCommandP);
  }

  public fetchAdminByAdminId(adminIdP: string, gameIdP?: string): Observable<IAdminQueryResult> {
    let url = `admin-user/${adminIdP}`;

    if (gameIdP && gameIdP.trim() !== '') {
      url += `/${gameIdP}`;
    }

    return this.apiService.MakeGetRequest<IAdminQueryResult>(url);
  }

  public validateEmailForGameAdminInvite(emailP: string, gameIdP: string): Observable<ValidateAdminEmailInviteResult> {
    return this.apiService.MakeGetRequest<any>(`admin-user/validate-email/game-admin/game/${gameIdP}/email/${emailP}`);
  }

  public validateEmailForCausableAdminInvite(emailP: string): Observable<ValidateAdminEmailInviteResult> {
    return this.apiService.MakeGetRequest<any>(`admin-user/validate-email/causable-admin/email/${emailP}`);
  }
}
