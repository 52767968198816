<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Details</span>
  <ng-container dialog-content>
    <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">
      <div *userHasCausableAdminAccess class="detail-section">
        <div class="detail-label">Sentry Event Id</div>
        <div class="detail-value">{{ supportRequest.SentryEventId }}
          <button mat-icon-button [cdkCopyToClipboard]="supportRequest.SentryEventId" (click)="copyToClipBoard()">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Player Id</div>
        <div class="detail-value">{{ supportRequest.PlayerId }}
          <button mat-icon-button [cdkCopyToClipboard]="supportRequest.PlayerId" (click)="copyToClipBoard()">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Player Name</div>
        <div class="detail-value">{{ supportRequest.PlayerName }}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Player Email</div>
        <div class="detail-value">{{ supportRequest.PlayerEmail }}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Error Type</div>
        <div class="detail-value">{{ displayErrorType(supportRequest.ErrorType) }}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Error Description</div>
        <div class="detail-value">{{ supportRequest.ErrorDescription }}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Created On</div>
        <div class="detail-value">{{ supportRequest.CreatedOn }}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Assignee</div>
        <div class="detail-value">{{ supportRequest.Assignee }}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Resolved</div>
        <div class="detail-value">{{ supportRequest.Resolved ? "Yes" : "No" }}</div>
      </div>

      @if (supportRequest.Resolved) {
        <div class="detail-section">
          <div class="detail-label">Resolved On</div>
          <div class="detail-value">{{ supportRequest.ResolvedOn }}</div>
        </div>

        <div class="detail-section">
          <div class="detail-label">Resolved By</div>
          <div class="detail-value">{{ supportRequest.ResolvedBy }}</div>
        </div>

        <div class="detail-section">
          <div class="detail-label">Resolution Description</div>
          <div class="detail-value">{{ supportRequest.ResolutionDescription }}</div>
        </div>
      }
    </div>
  </ng-container>
  @if (userHasPermissionToModifySupportRequests) {
    <ng-container actions>
      <button data-testid="assign-button" mat-flat-button color="primary" class="full-width" (click)="openAssignAndResolveDialog()">Assign & Resolve</button>
    </ng-container>
  }
</app-dialog-base>
