import {ColDef} from "ag-grid-community";
import {DatePipe} from "@angular/common";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SupportDeskColDefs {

  constructor(private datePipe: DatePipe) {
  }

  public errorTypes = [{name: "Cannot Receive Email", value: 1}, {
    name: "Cannot Login",
    value: 2
  }, {name: "Cannot Sign Up", value: 3},
    {name: "Cannot Play Tickets", value: 4}, {
      name: "Cannot Update Payment Method",
      value: 5
    }, {name: "Cannot Update Charity Pledge", value: 6},
    {name: "Cannot Update Password", value: 7}, {
      name: "Cannot Update Email",
      value: 8
    }, {name: "Cannot Update Personal Information", value: 9},
    {name: "Other", value: 10},
    {name: "Deactivated Numbers", value: 11}];

  public supportRequestColDefs: ColDef[] = [
    {
      headerName: "Player Name",
      field: 'PlayerName',
      resizable: true,
      sortable: true
    },
    {
      headerName: "Player Email",
      field: 'PlayerEmail',
      resizable: true,
      sortable: true
    },
    {
      headerName: "Error Type",
      field: 'ErrorType',
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        return this.errorTypes[params.value - 1].name;
      }
    },
    {
      headerName: "Created On",
      field: 'CreatedOn',
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: "Resolved",
      field: 'Resolved',
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Escalated",
      field: 'Escalated',
      resizable: true,
      sortable: true,
    }
  ];
}
