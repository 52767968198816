<app-component-level-error-message [errorMessage]="''"></app-component-level-error-message>
@if (uiState === uiStateForTemplate.ShowLoading) {
  <app-loading-card-content></app-loading-card-content>
} @else {
  <div class="text-end">
    <button mat-raised-button (click)="onBtnExport()">Download CSV</button>
  </div>
  <ag-grid-angular
    data-testid="game-charity-supporter-report-table"
    class="ag-theme-quartz margin-top-med"
    [tooltipShowDelay]=0
    domLayout='autoHeight'
    overlayNoRowsTemplate="<span>No charities for this game</span>"
    (gridReady)="onGridReady($event)"
    [rowData]="charityBreakdown"
    [columnDefs]="reportDefs">
  </ag-grid-angular>
}
