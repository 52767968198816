import {Component, OnInit, ViewChild} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {AsyncPipe, NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {SnackbarService} from "../../../services/snackbar.service";
import {IEmailBlast} from "../../../interfaces/IEmailBlast";
import {ActiveUserService} from "../../../services/active-user.service";
import {GameInstanceService} from "../../../services/game-instance.service";
import {EmailBlastService} from "../../../services/email-blast.service";
import {MatDialogRef} from "@angular/material/dialog";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {PlayersService} from "../../../services/players.service";
import {IPlayer} from "../../../interfaces/player/IPlayer";
import {MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow} from "@angular/material/chips";
import {MatIcon} from "@angular/material/icon";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatOption
} from "@angular/material/autocomplete";
import {map, Observable, of, startWith} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-email-blast',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatFormField,
    MatInputModule,
    MatButton,
    DialogBaseComponent,
    MatCheckbox,
    MatChipGrid,
    MatChipRow,
    MatChipRemove,
    MatIcon,
    MatChipInput,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatOption,
    AsyncPipe,
  ],
  templateUrl: './email-blast.component.html',
  styleUrl: './email-blast.component.css'
})
export class EmailBlastComponent implements OnInit{
  @ViewChild("emailAutoTrigger") emailAutocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild("emailAuto") emailAutocomplete!: MatAutocomplete;

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public players: IPlayer[] = [];
  public allEmailsForGame: string[] = [];
  public selectedEmails: string[] = [];
  public filteredEmails$: Observable<string[]> = of([]);
  public readonly separatorKeysCodes = [ENTER, COMMA];

  subjectControl = new FormControl<string|null>("", Validators.required);
  messageControl = new FormControl<string|null>("", Validators.required);
  sendToAllUsersControl = new FormControl<boolean>(false);
  shouldBypassUnsubscribeControl = new FormControl<boolean>(false);
  emailControl = new FormControl<string | null>(null);

  emailForm = new FormGroup({
    subject: this.subjectControl,
    message: this.messageControl,
    sendToAllUsers: this.sendToAllUsersControl,
    shouldByPassUnsubscribe: this.shouldBypassUnsubscribeControl
  });

  constructor(private snackbarService: SnackbarService,
              private gameInstanceService: GameInstanceService,
              private activeUserService: ActiveUserService,
              private emailBlastService: EmailBlastService,
              private playerService: PlayersService,
              private matDialogRef: MatDialogRef<EmailBlastComponent>,
              private errorHandlingService: ErrorHandlingService) {
  }

  ngOnInit() {
    this.playerService.getPlayersBySearchParams().subscribe({
      next: (result) => {
        this.players = result;
        result.forEach(player => this.allEmailsForGame.push(player.Email));
        this.allEmailsForGame.sort((a, b) => a.trim().localeCompare(b.trim()))
      },
      error: err => {
        this.errorHandlingService.displayDialogLevelErrorMessage("Failed to fetch players");
      }
    });
    this.filterEmailsOnValueChange();
  }

  filterEmailsOnValueChange() {
    this.filteredEmails$ = this.emailControl.valueChanges.pipe(
      startWith(null),
      map((email: string | null) => (email ? this._filter(email) : this.allEmailsForGame.slice()))
    )
  }

  selectedChip(eventP: MatAutocompleteSelectedEvent) {
    if (this.selectedEmails.includes(eventP.option.value)) {
      return;
    }
    this.selectedEmails.push(eventP.option.viewValue);
    this.resetAutocomplete();
  }

  resetAutocomplete() {
    this.emailControl.setValue(null);
  }

  emailInputChanged(valueP: string) {
    this.emailAutocompleteTrigger.autocompleteDisabled = valueP.length < 1;
    this.emailAutocomplete._isOpen = valueP.length > 1;
  }

  addEmail(eventP: MatChipInputEvent) {
    let input = eventP.chipInput;
    let value = eventP.value;

    if (!this.selectedEmails.includes(value)) {
      value = value.trim();
      if (value.length > 0) {
        this.selectedEmails.push(value);
      }
    }

    if (input) {
      input.clear();
    }

    this.resetAutocomplete();
  }

  removeEmail(indexP: number) {
    this.selectedEmails.splice(indexP, 1);
  }

  sendEmailBlast() {
    if (!this.emailForm.valid) {
      this.emailForm.markAllAsTouched();
      return;
    }

    if (!this.sendToAllUsersControl.value && this.selectedEmails.length == 0) {
      this.emailForm.markAllAsTouched();
      this.emailForm.updateValueAndValidity();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let command: IEmailBlast = {
      AdminId: this.activeUserService.activeUser().Id,
      GameId: this.gameInstanceService.activeGameInstance().GameId,
      GameInstanceId: this.gameInstanceService.activeGameInstance().Id,
      Message: this.messageControl.value!,
      Subject: this.subjectControl.value!,
      ShouldByPassUnsubscribe: this.shouldBypassUnsubscribeControl.value!,
      SendToAllPlayersInGame: this.sendToAllUsersControl.value!,
      Recipients: this.selectedEmails
    };

    this.emailBlastService.sendEmailBlast(command).subscribe({
      next: () => {
        this.snackbarService.openSuccessfulSnackBar("Message Sent!");
        this.matDialogRef.close();
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    });

  }

  private _filter(value: string): string[]
  {
    const filterValue = value.toLowerCase();
    return this.allEmailsForGame.filter((email: string) => email.toLowerCase().includes(filterValue));
  }

  splitPastedEmails(eventP: ClipboardEvent) {
   this.selectedEmails = eventP.clipboardData!.getData("text").split(/\r?\n */).filter((email: string) => email.trim().length > 0);
  }
}
