<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
@if (uiState === uiStateEnumForTemplate.ShowLoading) {
  <app-loading-card-content></app-loading-card-content>
} @else if (uiState === uiStateEnumForTemplate.ShowData && standardGameReport) {
  <div>
    <div class="justify-space-between flex-row align-start">
      <div>
        <div class="section-header">Standard Game Report</div>
        <div class="ticket-type-header">Draw Date: {{ standardGameReport.EndDate | date: 'MMM d, y HH:mm' }}</div>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="generatePDF()"
                data-testid="standard-report-generate-pdf-button">Generate
          PDF
        </button>
      </div>
    </div>

    <div class="margin-top-large">

      <h4 class="thick-bottom-border mb-3 pb-2">Game Details</h4>
      <c-container>
        <c-row>
          <c-col>
            <div>
              <div class="report-label">License #</div>
              <div class="report-value"
                   data-testid="standard-report-license-number">{{ standardGameReport.LicenceNumber }}
              </div>
            </div>
            <div>
              <div class="report-label">Game End Date</div>
              <div class="report-value"
                   data-testid="standard-report-game-end-date">{{ standardGameReport.EndDate | date: 'MMM d, y HH:mm' }}
              </div>
            </div>
            <div>
              <div class="report-label">Draw Time</div>
              <div class="report-value"
                   data-testid="standard-report-draw-time">{{ standardGameReport.DrawTime | date: 'MMM d, y HH:mm' }}
              </div>
            </div>
            <div>
              <div class="report-label">Drawn #</div>
              <div class="report-value"
                   data-testid="standard-report-winning-number">{{ standardGameReport.WinningNumber }}
              </div>
            </div>
            <div>
              <div class="report-label">Drawn Person</div>
              <div class="report-value"
                   data-testid="standard-report-winner-name">{{ standardGameReport.WinnerName ? standardGameReport.WinnerName : '' }}
              </div>
            </div>
            <div>
              <div class="report-label">Total Tickets</div>
              <div class="report-value" data-testid="standard-report-total-tickets">{{ standardGameReport.TicketsSold }}
              </div>
            </div>
          </c-col>
        </c-row>
      </c-container>

      <h4 class="thick-bottom-border mb-3 pb-2">Payout Details</h4>

      <c-container>
        <c-row>
          <c-col>
            @if (activeGame?.Type === gameTypes.GoldRush) {
              <div>
                <div class="report-label">Winning Number Played</div>
                <div class="report-value"
                     data-testid="standard-report-winner-played">{{ standardGameReport.WinnerPlayed ? 'Yes' : 'No' }}
                </div>
              </div>
            }

            <div>
              <div class="report-label">Winner Payout</div>
              <div class="report-value"
                   data-testid="standard-report-winner-payout">{{ standardGameReport.WinnerAmount | formatCurrency }}
              </div>
            </div>

            @if (standardGameReport.WinnerPayoutStatus) {
              <div>
                <div class="report-label">Winner Payout Status</div>
                <div class="report-value"
                     data-testid="standard-report-winner-claimed-prize">{{ standardGameReport.WinnerPayoutStatus | winnerPayoutStatus }}
                </div>
              </div>
            }


          </c-col>
        </c-row>
      </c-container>

      <h4 class="thick-bottom-border mb-3 pb-2">Pot Total Breakdown</h4>

      <c-container>
        <c-row>
          <c-col>
            <div>
              <div class="report-label">Seed Contribution to Pot</div>
              <div class="report-value"
                   data-testid="standard-report-seed-total">{{ standardGameReport.SeedTotal | formatCurrency }}
              </div>
            </div>

            @if (activeGame?.Type === gameTypes.GoldRush) {
              <div>
                <div class="report-label">Rollover Contribution</div>
                <div class="report-value"
                     data-testid="standard-report-previous-pot">{{ standardGameReport.PreviousPotTotal | formatCurrency }}
                </div>
              </div>
            }

            <div>
              <div class="report-label">Refund Total</div>
              <div class="report-value red-text" data-testid="standard-report-refund-total">(-{{ 0 | formatCurrency }})
              </div>
            </div>

            <div>
              <div class="report-label">Gross Sales</div>
              <div class="report-value"
                   data-testid="standard-report-total-pot-contribution">{{ standardGameReport.GrossSales | formatCurrency }}
              </div>
            </div>
            <div class="ms-4">
              <div>
                <div class="report-label bold-text">Manual Sales</div>
                <div class="report-value"
                     data-testid="standard-report-total-pot-contribution">{{ standardGameReport.ManualSales | formatCurrency }}
                </div>
              </div>

              <div>
                <div class="report-label bold-text">Autoplay Sales</div>
                <div class="report-value"
                     data-testid="standard-report-total-pot-contribution">{{ standardGameReport.AutoplaySales | formatCurrency }}
                </div>
              </div>
            </div>
            <hr>
            <div>
              <div class="report-label">Pot Total</div>
              <div class="report-value bold-text"
                   data-testid="standard-report-total-pot">{{ standardGameReport.PotTotal | formatCurrency }}
              </div>
            </div>

          </c-col>
        </c-row>
      </c-container>

      <h4 class="thick-bottom-border mb-3 pb-2">Fee Breakdown</h4>

      <c-container>
        <c-row>
          <c-col>
            <div>
              <div class="report-label">Causable Fees</div>
              <div class="report-value"
                   data-testid="standard-report-total-causable-fee">{{ standardGameReport.TotalCausableFees | formatCurrency }}
              </div>
            </div>

            <div>
              <div class="report-label">HST on Causable Fees</div>
              <div class="report-value"
                   data-testid="standard-report-hst-on-causable">{{ standardGameReport.HstOnCausableFees | formatCurrency }}
              </div>
            </div>

            <div>
              <div class="report-label">Stripe Fees</div>
              <div class="report-value"
                   data-testid="standard-report-total-stripe-fee">{{ standardGameReport.TotalStripeFees | formatCurrency }}
              </div>
            </div>

            <div>
              <div class="report-label">Charity Host Admin Fees</div>
              <div class="report-value"
                   data-testid="standard-report-admin-fees">{{ standardGameReport.TotalAdministrationCommissionFees | formatCurrency }}
              </div>
            </div>
            <hr>
            <div>
              <div class="report-label">Total Fees</div>
              <div class="report-value bold-text"
                   data-testid="standard-report-admin-fees">{{ totalFees | formatCurrency }}
              </div>
            </div>
          </c-col>
        </c-row>
      </c-container>

      <h4>Charity Revenue Breakdown</h4>
      <hr>
      <div>
        <div class="report-label bold-text">Gross Sales</div>
        <div class="report-value bold-text"
             data-testid="standard-report-total-pot-contribution">{{ standardGameReport.GrossSales | formatCurrency }}
        </div>
      </div>

      <div>
        <div class="report-label">Prize Amount</div>
        <div class="report-value red-text"
             data-testid="standard-report-admin-fees">(-{{ standardGameReport.TotalPotContribution | formatCurrency }})
        </div>
      </div>

      <div>
        <div class="report-label">Total Fees</div>
        <div class="report-value red-text"
             data-testid="standard-report-admin-fees">
          (-{{ totalFees | formatCurrency }}
          )
        </div>
      </div>
      <hr>
      <div>
        <div class="report-label">Net Proceeds For Charity</div>
        <div class="report-value bold-text"
             data-testid="standard-report-total-charity-contribution">{{ standardGameReport.TotalCharityContribution | formatCurrency }}
        </div>
      </div>

      <ag-grid-angular
        data-testid="charity-contributions-grid"
        class="ag-theme-quartz margin-top-med margin-left-med"
        (gridReady)="onGridReady($event)"
        [animateRows]="true"
        [domLayout]="'autoHeight'"
        overlayNoRowsTemplate="<span>No tickets for this game</span>"
        [rowData]="standardGameReport.CharityContributionsBreakdown"
        [columnDefs]="groupBreakdownReportDefs">
      </ag-grid-angular>

    </div>
  </div>

}

