import {Component, Input} from '@angular/core';
import {IActiveGameInstance} from "../../interfaces/IActiveGameInstance";
import {forkJoin, map, Observable, of, timer} from "rxjs";
import {UIStateEnum} from "../../enum/UIStateEnum";
import Chart, {ChartConfiguration} from "chart.js/auto";
import {CountdownService} from "../../services/countdown.service";
import {ReportingService} from "../../services/reporting.service";
import {IPlayersAndTicketsPerGameInstance} from "../../interfaces/reporting/IPlayersAndTicketsPerGameInstance";
import {AsyncPipe, DatePipe, NgIf, UpperCasePipe} from "@angular/common";
import {IManualAndAutoPlayTickets} from "../../interfaces/reporting/IManualAndAutoPlayTickets";
import {FormatCurrencyPipe} from "../../pipes/format-currency.pipe";
import {ISalesPerDay} from "../../interfaces/reporting/ISalesPerDay";
import {ICharitySalesPerDraw} from "../../interfaces/reporting/ICharitySalesPerDraw";
import {
  ComponentLevelErrorMessageComponent
} from "../display-errors/component-level-error-message/component-level-error-message.component";
import {PipesModule} from "../../pipes/pipes.module";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {MatTableModule} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {GameService} from "../../services/game.service";
import {MatButtonModule} from "@angular/material/button";
import {MatDialog} from "@angular/material/dialog";
import {WinnerPayoutStatusTypes} from "../../enum/WinnerPayoutStatusTypes";
import {GameInstanceService} from "../../services/game-instance.service";
import {IDrawNumberRequest} from "../../interfaces/IDrawNumberRequest";
import {ActiveUserService} from "../../services/active-user.service";
import {MatTooltip} from "@angular/material/tooltip";
import {SnackbarService} from "../../services/snackbar.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {IUnclaimedWinnersResult} from "../../interfaces/IUnclaimedWinnersResult";
import {IActiveGameInstanceWithTotals} from "../../interfaces/IActiveGameInstanceWithTotals";

@Component({
  selector: 'app-display-active-game-instance',
  standalone: true,
  templateUrl: './display-active-game-instance.component.html',
  imports: [
    ComponentLevelErrorMessageComponent,
    DatePipe,
    PipesModule,
    UpperCasePipe,
    HasPermissionDirective,
    MatIcon,
    MatTableModule,
    AsyncPipe,
    NgIf,
    MatButtonModule,
    MatTooltip,
    MatProgressSpinner
  ],
  styleUrls: ['./display-active-game-instance.component.scss']
})
export class DisplayActiveGameInstanceComponent {

  @Input() set setSelectedGameInstance(gameInstanceP: IActiveGameInstance) {
    this.errorMessage = '';
    this.selectedGameInstance = gameInstanceP;
    forkJoin([
      this.reportingService.getManualVsAutoPlayTickets(this.selectedGameInstance.GameId, gameInstanceP.Id),
      this.reportingService.salesPerDay(this.selectedGameInstance.GameId, gameInstanceP.Id),
      this.gameInstanceService.fetchActiveGameInstanceByGameWithTotals(this.selectedGameInstance.GameId),
      this.reportingService.topTenCharityContributors(this.selectedGameInstance.GameId, gameInstanceP.Id),
      this.reportingService.getPastGamesPlayersAndTickets(
        this.selectedGameInstance.GameId,
        5)
    ])
      .subscribe({
        next: ([manualAutoplayTicketP, salesPerDayP, gameInstanceWithTotals, charitiesTotalsP, playersAndTicketsP]) => {
          this.createCountdownSubscription(new Date(this.selectedGameInstance.EndedOn));
          this.generateManualVsAutoPlayChart(manualAutoplayTicketP);
          this.generateTopCharityContributors(charitiesTotalsP);
          this.generateTicketChart(gameInstanceWithTotals);
          this.generateSalesPerDayChart(salesPerDayP);
          this.generatePlayersTicketsOverTimeLineChart(playersAndTicketsP);
        },
        error: () => {
          this.errorMessage = 'Looks like there was an issue retrieving data for this draw.' +
            ' Please reload the page or reach out to a system administrator.'
        }
      })
  }

  public selectedGameInstance!: IActiveGameInstance;
  public errorMessage: string = '';
  public manualAutoPlayChart!: Chart;
  public noManualAutoPlayChartData: boolean = false;
  public ticketsChart!: Chart;
  public noTicketChartData: boolean = false;
  public playersTicketsLineChart!: Chart;
  public salesPerDayLineChart!: Chart;
  public noSalesPerDayLineChartData: boolean = false;
  public charitySalesBarGraph!: Chart;
  public noCharitySalesBarGraphData: boolean = false;
  public endDateCountDown$: Observable<string> = of('');
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;
  public winnersPendingDecisions$: Observable<IUnclaimedWinnersResult> = of({UnclaimedWinners: []});
  public columnsToDisplay = ["gameInstanceId", "playerNumberId", "playerWinnings", "playerName", "playerEmail",
    "createdOn"];

  protected readonly PermissionTypes = PermissionTypes;
  protected readonly WinnerPayoutStatusTypes = WinnerPayoutStatusTypes;

  constructor(private countdownService: CountdownService,
              private datePipe: DatePipe,
              private formatCurrencyPipe: FormatCurrencyPipe,
              private reportingService: ReportingService,
              private gameService: GameService,
              private matDialog: MatDialog,
              private gameInstanceService: GameInstanceService,
              private activeUserService: ActiveUserService,
              private snackBarService: SnackbarService) {
  }

  private generateTopCharityContributors(charitiesTotalsP: ICharitySalesPerDraw[]) {
    if (this.charitySalesBarGraph) {
      this.charitySalesBarGraph.destroy();
    }

    if (charitiesTotalsP.length === 0) {
      this.noCharitySalesBarGraphData = true;
      return;
    }

    let salesCntxt = document.getElementById('charitySalesBarGraph') as HTMLCanvasElement;
    const salesData = {
      labels: charitiesTotalsP.map((perCharity) => perCharity.CharityName),
      datasets: [
        {
          label: 'Top Charity Contributors',
          data: charitiesTotalsP.map((perCharity) => perCharity.Sales),
          fill: false,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          borderWidth: 1
        }
      ]
    };

    const config: ChartConfiguration = {
      type: 'bar',
      data: salesData,
      options: {
        scales: {
          y: {
            ticks: {
              callback: (value) => {
                return this.formatCurrencyPipe.transform(Number(value));
              }
            }
          }
        },
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                return this.formatCurrencyPipe.transform(context.raw);
              }
            }
          },
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Top Charity Contributors '
          }
        }
      }
    };

    if (salesCntxt) {
      this.charitySalesBarGraph = new Chart(salesCntxt, config)
    }
  }

  private generateSalesPerDayChart(salesPerDayP: ISalesPerDay[]) {
    if (this.salesPerDayLineChart) {
      this.salesPerDayLineChart.destroy();
    }

    if (salesPerDayP.length === 0) {
      this.noSalesPerDayLineChartData = true;
      return;
    }

    salesPerDayP = salesPerDayP.sort((a, b) => a.SaleDate < b.SaleDate ? -1 : 1);

    let salesCntxt = document.getElementById('salesPerDayLineChart') as HTMLCanvasElement;

    const salesData = {
      labels: salesPerDayP.map((perDay) => this.datePipe.transform(perDay.SaleDate, 'MMM d', 'GMT')),
      datasets: [
        {
          label: 'Tickets Sold',
          data: salesPerDayP.map((perDay) => perDay.TicketsSold),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    };

    const config: ChartConfiguration = {
      type: 'line',
      data: salesData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Sales Per Day'
          }
        }
      }
    };

    if (salesCntxt) {
      this.salesPerDayLineChart = new Chart(salesCntxt, config)
    }

  }

  private generatePlayersTicketsOverTimeLineChart(playersAndTicketsP: IPlayersAndTicketsPerGameInstance[]) {
    if (this.playersTicketsLineChart) {
      this.playersTicketsLineChart.destroy();
    }

    playersAndTicketsP = playersAndTicketsP
      .sort((a, b) => a.DrawDate < b.DrawDate ? -1 : 1);


    let playersCntxt = document.getElementById('playersTicketsLineChart') as HTMLCanvasElement;

    const playersData = {
      labels: playersAndTicketsP.map((perGame) => this.datePipe.transform(perGame.DrawDate, 'MMM d')),
      datasets: [
        {
          label: 'Players in draw',
          data: playersAndTicketsP.map((perGame) => perGame.TotalPlayedPlayers),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        },
        {
          label: 'Tickets in draw',
          data: playersAndTicketsP.map((perGame) => perGame.TotalPlayedTickets),
          fill: false,
          borderColor: 'rgb(125, 100, 150)',
          tension: 0.1
        }
      ]
    };

    const config: ChartConfiguration = {
      type: 'line',
      data: playersData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Players/Tickets Last 5 Games'
          }
        }
      }
    };

    if (playersCntxt) {
      this.playersTicketsLineChart = new Chart(playersCntxt, config)
    }

  }

  public generateManualVsAutoPlayChart(manualAutoPlayReportP: IManualAndAutoPlayTickets) {
    if (this.manualAutoPlayChart) {
      this.manualAutoPlayChart.destroy();
    }

    if (!manualAutoPlayReportP.AutoPlayedTicketsSales && !manualAutoPlayReportP.ManuallyPurchasedTicketsSales) {
      this.noManualAutoPlayChartData = true;
      return;
    }

    let ticketsCntxt = document.getElementById('manualAutoPlayChart') as HTMLCanvasElement;

    const config: any = {
      type: 'pie',
      data: {
        datasets: [{
          data: [
            manualAutoPlayReportP.AutoPlayedTicketsSales,
            manualAutoPlayReportP.ManuallyPurchasedTicketsSales],
          backgroundColor: [
            'rgb(255, 205, 86)',
            'rgb(54, 162, 235)',
          ],
        }],
        labels: ['Auto Play Sales ($)', 'Manual Sales ($)'],
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                return this.formatCurrencyPipe.transform(context.parsed);
              }
            }
          },
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Autoplay vs Manual Ticket Sales'
          }
        }
      }
    };

    if (ticketsCntxt) {
      this.manualAutoPlayChart = new Chart(ticketsCntxt, config);
    }

  }

  public generateTicketChart(gameInstanceWithTotalsP: IActiveGameInstanceWithTotals) {
    if (this.ticketsChart) {
      this.ticketsChart.destroy();
    }

    if (!gameInstanceWithTotalsP.TotalPlayedTickets && !gameInstanceWithTotalsP.TotalUnplayedTickets) {
      this.noTicketChartData = true;
      return;
    }

    let ticketsCntxt = document.getElementById('ticketsChart') as HTMLCanvasElement;

    const ticketsData = {
      labels: ['Played Tickets', 'Unplayed Tickets'],
      datasets: [
        {
          data: [
            gameInstanceWithTotalsP.TotalPlayedTickets,
            gameInstanceWithTotalsP.TotalUnplayedTickets],
          backgroundColor: ['#9BD0F5', '#e25d22'],
        }
      ]
    };

    const config: ChartConfiguration = {
      plugins: [],
      type: 'pie',
      data: ticketsData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Tickets'
          }
        }
      }
    };

    if (ticketsCntxt) {
      this.ticketsChart = new Chart(ticketsCntxt, config);
    }

  }

  private createCountdownSubscription(dateP: Date): void {
    this.endDateCountDown$ = timer(0, 1000).pipe(map(() => {
      const end_date = dateP;
      if (end_date) {
        return this.countdownService.formatRemainingTimeUntilDraw(end_date);
      }

      return '';
    }));
  }

  redrawInstance(gameInstanceIdP: string) {
    let command: IDrawNumberRequest = {
      GameInstanceId: gameInstanceIdP,
      AdminId: this.activeUserService.activeUser().Id,
      OverrideValidation: false,
      IsRedraw: true
    }

    this.uiState = UIStateEnum.ShowLoading;

    this.gameInstanceService.drawWinner(command).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openSuccessfulSnackBar("Game Instance Successfully Redrawn!");
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openErrorSnackBar(err);
      }
    });
  }
}
