import {Component, inject, OnInit} from '@angular/core';
import {NotificationsService} from "../../services/notifications.service";
import {forkJoin} from "rxjs";
import {ActiveUserService} from "../../services/active-user.service";
import {GameService} from "../../services/game.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {INotificationType} from "../../interfaces/notifications/INotificationType";
import {IAdminNotificationPreferences} from "../../interfaces/notifications/IAdminNotificationPreferences";
import {MatListOption, MatSelectionList, MatSelectionListChange} from "@angular/material/list";
import {MatButton} from "@angular/material/button";
import {IUpdateAdminNotificationPreferences} from "../../interfaces/notifications/IUpdateAdminNotificationPreferences";
import {SnackbarService} from "../../services/snackbar.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-notification-preferences',
  standalone: true,
  imports: [
    MatSelectionList,
    MatListOption,
    MatButton,
    MatProgressSpinner
  ],
  templateUrl: './notification-preferences.component.html',
  styleUrl: './notification-preferences.component.css'
})
export class NotificationPreferencesComponent implements OnInit {

  private notificationService: NotificationsService = inject(NotificationsService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private gameService: GameService = inject(GameService);

  protected uiState: UIStateEnum = UIStateEnum.ShowLoading
  protected uiStateForTemplate = UIStateEnum;
  protected potentialNotificationTypes: INotificationType[] = [];
  protected adminNotificationPreferences: IAdminNotificationPreferences[] = [];
  protected selectedNotificationTypes: number[] = []; // Holds the IDs of selected notification types

  ngOnInit(): void {
    this.fetchNotificationPreferenceData();
  }

  isNotificationTypeSelected(notificationTypeId: number): boolean {
    return this.selectedNotificationTypes.includes(Number(notificationTypeId));
  }

  onSelectionChange(event: MatSelectionListChange): void {
    const selectedOptions = event.source.selectedOptions.selected;

    // Update the selectedNotificationTypes array based on the checked boxes
    this.selectedNotificationTypes = selectedOptions.map((option: MatListOption) => Number(option.value));
  }

  private fetchNotificationPreferenceData() {
    const activeUser = this.activeUserService.activeUser().Id;
    const activeGame = this.gameService.activeGame().Id;
    forkJoin([
      this.notificationService.fetchNotificationTypes(),
      this.notificationService.fetchAdminNotificationPreferences(activeUser, activeGame)
    ]).subscribe({
      next: ([notificationTypes, adminPreferences]) => {
        this.potentialNotificationTypes = notificationTypes;
        this.selectedNotificationTypes = adminPreferences.map(p => p.NotificationType);
        this.adminNotificationPreferences = adminPreferences;
        this.uiState = UIStateEnum.ShowData;
      }, error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
        this.uiState = UIStateEnum.ShowData;

      }
    })
  }

  saveMyPreferences() {
    this.uiState = UIStateEnum.ShowRequestProcessing;

    const updatePreferencesCommand: IUpdateAdminNotificationPreferences = {
      GameId: this.gameService.activeGame().Id,
      AdminId: this.activeUserService.activeUser().Id,
      NotificationTypes: this.selectedNotificationTypes
    };

    this.notificationService.updateUserPreferences(updatePreferencesCommand).subscribe({
      next: () => {
        this.snackbarService.openSuccessfulSnackBar('Successfully updated your notification preferences!');
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.snackbarService.openErrorSnackBar('Error updated your preferences. Please try again or contact system admin for assistance');
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }
}
