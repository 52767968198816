export enum PlayerScreenState {
  GeneralInfo = 1,
  PlayerNumbersView = 2,
  PledgesView = 3,
  NotesView = 4,
  TransactionView = 5,
  SuspensionsView = 6,
  SendGridView = 7,
  CausableSuspensionsView = 8
}
