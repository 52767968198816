import {Component, Inject} from '@angular/core';
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatButtonModule} from "@angular/material/button";
import {PlayersService} from "../../../services/players.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IResolvePlayerSuspension} from "../../../interfaces/player/IResolvePlayerSuspension";
import {SnackbarService} from "../../../services/snackbar.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {NgIf} from "@angular/common";
import {IPlayerSuspension} from "../../../interfaces/player/IPlayerSuspension";
import {IResolvePlayerCausableSuspension} from "../../../interfaces/player/IResolvePlayerCausableSuspension";
import {Observable} from "rxjs";

@Component({
  selector: 'app-resolve-player-suspension',
  standalone: true,
  imports: [
    DialogBaseComponent,
    MatButtonModule,
    NgIf
  ],
  templateUrl: './resolve-player-suspension.component.html',
  styleUrl: './resolve-player-suspension.component.css'
})
export class ResolvePlayerSuspensionComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public suspension: IPlayerSuspension,
              private playerService: PlayersService,
              private activeUserService: ActiveUserService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private dialogRef: MatDialogRef<ResolvePlayerSuspensionComponent>) {
  }

  public resolveSuspension() {
    this.uiState = UIStateEnum.ShowLoading;

    let resolve_suspension_call;
    if (this.suspension.IsCausableSuspension) {
      resolve_suspension_call = this.resolveCausableSuspension();
    } else {
      resolve_suspension_call = this.resolveGameSuspension();
    }

    resolve_suspension_call.subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openSuccessfulSnackBar(this.suspension.Resolved ? "Successfully unresolved suspension" :
        "Successfully resolved suspension");
        this.dialogRef.close(true);
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(this.suspension.Resolved ? "Failed to unresolve suspension" :
          "Failed to resolve suspension", err)
      }
    })
  }

  private resolveGameSuspension(): Observable<any> {
    let command: IResolvePlayerSuspension = {
      AdminId: this.activeUserService.activeUser().Id,
      GameId: this.suspension.GameId,
      PlayerId: this.suspension.PlayerId,
      SuspensionId: this.suspension.Id
    }

    return this.playerService.resolvePlayerSuspension(command);
  }

  private resolveCausableSuspension(): Observable<any> {
    let command: IResolvePlayerCausableSuspension = {
      AdminId: this.activeUserService.activeUser().Id,
      PlayerId: this.suspension.PlayerId,
      SuspensionId: this.suspension.Id
    }

    return this.playerService.resolvePlayerCausableSuspension(command);
  }
}
