import {Component} from "@angular/core";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {IStandardGameReport} from "../../../interfaces/IStandardGameReport";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";
import {ReportingService} from "../../../services/reporting.service";
import * as pdfMake from 'pdfmake/build/pdfmake';
import {Content, TDocumentDefinitions} from "pdfmake/interfaces";
import {FormatCurrencyPipe} from "../../../pipes/format-currency.pipe";
import {DatePipe} from "@angular/common";
import {PdfUtilityService} from "../../../services/pdf-utility.service";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {ReportBaseComponent} from "../../../pages/reports/report-base.component";
import {GameService} from "../../../services/game.service";
import {IGameInstanceShared} from "../../../interfaces/IGameInstanceShared";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {ICharityContributionsBreakdown} from "../../../interfaces/ICharityContributionsBreakdown";
import {IPdfSorting} from "../../../interfaces/reporting/IPdfSorting";
import {
  ComponentLevelErrorMessageComponent
} from "../../display-errors/component-level-error-message/component-level-error-message.component";
import {LoadingCardContentComponent} from "../../../shared/loading-card-content/loading-card-content.component";
import {MatButton} from "@angular/material/button";
import {PipesModule} from "../../../pipes/pipes.module";
import {AgGridAngular} from "ag-grid-angular";
import {ColComponent, ContainerComponent, RowComponent} from "@coreui/angular";
import {WinnerPayoutStatusPipe} from "../../../pipes/winner-payout-status.pipe";

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-standard-game-report',
  standalone: true,
  templateUrl: './standard-game-report.component.html',
  imports: [
    ComponentLevelErrorMessageComponent,
    LoadingCardContentComponent,
    DatePipe,
    MatButton,
    PipesModule,
    AgGridAngular,
    ContainerComponent,
    RowComponent,
    ColComponent
  ],
  styleUrls: ['../../../pages/reports/reports.scss']
})
export class StandardGameReportComponent extends ReportBaseComponent {

  public groupBreakdownReportDefs: ColDef[] = [
    {
      headerName: 'Group',
      resizable: true,
      sortable: true,
      field: 'Name',
      flex: 1
    },
    {
      headerName: 'Total Amount',
      resizable: true,
      sortable: true,
      field: 'CharityContribution',
      flex: 1,
      valueFormatter: params => {
        const converted = this.currencyPipe.transform(params.value);
        return converted ? converted : '';
      }
    },
    {
      headerName: 'Number of Players',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      flex: 1,
      field: 'TotalNumberOfPlayers'
    }
  ];

  public columnApi!: GridApi;
  public standardGameReport: IStandardGameReport | null = null;
  public totalFees: number = 0;

  constructor(reportingService: ReportingService,
              currencyPipe: FormatCurrencyPipe,
              winnerPayoutStatusPipe: WinnerPayoutStatusPipe,
              pdfUtilityService: PdfUtilityService,
              datePipe: DatePipe,
              gameService: GameService) {
    super(gameService, reportingService, currencyPipe, winnerPayoutStatusPipe, pdfUtilityService, datePipe)
  }

  generateReport(instanceP: IGameInstanceShared) {
    this.uiState = UIStateEnum.ShowLoading;
    this.standardGameReport = null;

    this.reportingService.getStandardGameInstanceReportData(instanceP.Id).subscribe({
      next: (reportP) => {
        this.standardGameReport = reportP;
        this.calculateTotalFees(this.standardGameReport);
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.errorMessage = 'Looks there is no draw report data available for this game';
        this.uiState = UIStateEnum.ShowData;
      }
    });
  }

  calculateTotalFees (standardGameReportP: IStandardGameReport) {
    this.totalFees = standardGameReportP.HstOnCausableFees + standardGameReportP.TotalCausableFees + standardGameReportP.TotalStripeFees + standardGameReportP.TotalAdministrationCommissionFees;
  }

  onGridReady(params: GridReadyEvent<ICharityContributionsBreakdown>) {
    this.columnApi = params.api;
  }

  generatePDF() {
    const contentP: Content[] = [];
    if (this.standardGameReport) {

      contentP.push({
        text: `Standard Game Report for Draw Date ${this.datePipe.transform(this.chosenGameInstance?.EndedOn, 'yyyy-MM-dd')}`,
        style: this.pdfUtilityService.PdfStyles.pageTitle,
        margin: [0, 0, 0, 8]
      });

      contentP.push(this.getGameDetails(this.standardGameReport));
      contentP.push(this.pdfUtilityService.AddLineBreak());
      contentP.push(this.getPayoutDetails(this.standardGameReport));
      contentP.push(this.pdfUtilityService.AddLineBreak());
      contentP.push(this.getPotDetails(this.standardGameReport));
      contentP.push(this.pdfUtilityService.AddLineBreak());
      contentP.push(this.getFeeDetails(this.standardGameReport));
      contentP.push(this.pdfUtilityService.AddLineBreak());
      contentP.push(this.getCharityDetails(this.standardGameReport));
      contentP.push(this.pdfUtilityService.AddLineBreak());

      const docDef: TDocumentDefinitions = {
        pageMargins: [40, 40, 40, 40],
        footer: (currentPage, pageCount) => {
          return {
            text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
            style: this.pdfUtilityService.PdfStyles.footer
          };
        },
        content: contentP
      };

      const pdf = pdfMake.createPdf(docDef);

      pdf.open();
    }
  }

  private determineSortForPDF(): IPdfSorting | null {
    const sortedColumn = this.columnApi.getColumnState().find((col) => col.sort !== null);
    if (sortedColumn) {
      return {
        columnToSort: sortedColumn.colId,
        direction: sortedColumn.sort
      }
    }

    return null;
  }

  private sortColsForPdf(charityBreakdownP: ICharityContributionsBreakdown[], sortP: IPdfSorting): ICharityContributionsBreakdown[] {
    return charityBreakdownP.sort((a, b) => {
      // @ts-ignore
      const propA = a[sortP.columnToSort];
      // @ts-ignore
      const propB = b[sortP.columnToSort];

      if (sortP.direction === "asc") {
        return propA - propB;
      } else if (sortP.direction === "desc") {
        return propB - propA;
      } else {
        // If direction is not specified or null, no sorting is applied
        return 0;
      }
    });
  }

  private getCharityDetails(standardGameReportP: IStandardGameReport): Content[] {
    const charityDetails: Content[] = [];
    let charityBreakdown = this.standardGameReport?.CharityContributionsBreakdown;
    const getAnyColSorts = this.determineSortForPDF();
    if (getAnyColSorts && charityBreakdown) {
      charityBreakdown = this.sortColsForPdf(charityBreakdown, getAnyColSorts)
    }

    charityDetails.push(this.pdfUtilityService.generateSectionTitle('Charity Revenue Breakdown'));
    charityDetails.push(this.pdfUtilityService.generateLabelValueRow('Gross Sales: ', `${this.currencyPipe.transform(standardGameReportP.GrossSales)}`));
    charityDetails.push(this.pdfUtilityService.generateLabelValueRow('Prize Amount: ', `(-${this.currencyPipe.transform(standardGameReportP.TotalPotContribution)})`));
    charityDetails.push(this.pdfUtilityService.generateLabelValueRow('Total Fees: ', `(-${this.currencyPipe.transform(this.totalFees)})`));
    charityDetails.push(this.pdfUtilityService.generateLabelValueRow('Net Proceeds for Charity: ', `${this.currencyPipe.transform(standardGameReportP.TotalCharityContribution)}`),);

    const charityBreakdownContent: Content = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        body: [
          [
            {
              text: 'Group',
              fontSize: 10
            },
            {
              text: 'Total Amount',
              fontSize: 10
            },
            {
              text: 'Number of Players',
              fontSize: 10
            }
          ]
        ]
      },
      margin: [0, 16, 0, 16]
    };

    if (charityBreakdown && charityBreakdown?.length > 0) {
      charityBreakdown.forEach((contributionP) => {
        charityBreakdownContent.table.body.push([
          contributionP.Name, `${this.currencyPipe.transform(contributionP.CharityContribution)}`, contributionP.TotalNumberOfPlayers
        ]);
      });
    }

    charityDetails.push(charityBreakdownContent);
    return charityDetails;
  }

  private getPayoutDetails(standardGameReportP: IStandardGameReport): Content[] {
    const payoutDetails: Content[] = [];
    payoutDetails.push(this.pdfUtilityService.generateSectionTitle('Payout Details'));
    const columnOne = [
      this.pdfUtilityService.generateLabelValueRow('Winning Number Played: ', `${standardGameReportP.WinnerPlayed}`),
      this.pdfUtilityService.generateLabelValueRow('Winner Payout: ', `${this.currencyPipe.transform(standardGameReportP.WinnerAmount)}`)
    ];

    if (standardGameReportP.WinnerPayoutStatus) {
      columnOne.push(this.pdfUtilityService.generateLabelValueRow('Winner Payout Status: ', this.winnerPayoutStatusPipe.transform(standardGameReportP.WinnerPayoutStatus)))
    }

    const gameColumns: Content = {
      columns: [
        columnOne
      ]
    };
    payoutDetails.push(gameColumns);
    return payoutDetails;
  }

  private getGameDetails(standardGameReportP: IStandardGameReport): Content[] {
    const gameDetails: Content[] = [];
    gameDetails.push(this.pdfUtilityService.generateSectionTitle('Game Details'));
    const columnOne = [
      this.pdfUtilityService.generateLabelValueRow('License #: ', `${standardGameReportP.LicenceNumber}`),
      this.pdfUtilityService.generateLabelValueRow('Game End Date : ', `${this.datePipe.transform(standardGameReportP.EndDate, 'yyyy-MM-dd h:mm a')}`),
      this.pdfUtilityService.generateLabelValueRow('Draw Time : ', `${this.datePipe.transform(standardGameReportP.DrawTime, 'yyyy-MM-dd h:mm a')}`),
      this.pdfUtilityService.generateLabelValueRow('Drawn #: ', `${standardGameReportP.WinningNumber}`),
      this.pdfUtilityService.generateLabelValueRow('Drawn Person: ', `${standardGameReportP.WinnerName}`),
      this.pdfUtilityService.generateLabelValueRow('Total Tickets: ', `${standardGameReportP.TicketsSold}`)
    ];

    const gameColumns: Content = {
      columns: [
        columnOne
      ]
    };
    gameDetails.push(gameColumns);
    return gameDetails;
  }

  private getPotDetails(standardGameReportP: IStandardGameReport): Content[] {
    const potDetails: Content[] = [];
    potDetails.push(this.pdfUtilityService.generateSectionTitle('Pot Total Breakdown'));

    let columnOne = [];
    columnOne.push(this.pdfUtilityService.generateLabelValueRow('Seed Contribution to Pot: ', `${this.currencyPipe.transform(standardGameReportP.SeedTotal)}`));

    if (this.activeGame?.Type === GameTypeEnum.GoldRush) {
      columnOne.push(this.pdfUtilityService.generateLabelValueRow('Rollover Contribution: ', `${this.currencyPipe.transform(standardGameReportP.PreviousPotTotal)}`));
    }

    columnOne.push([
      this.pdfUtilityService.generateLabelValueRow('Refund Total: ', `(-${this.currencyPipe.transform(0)})`),
      this.pdfUtilityService.generateLabelValueRow('Gross Sales: ', `${this.currencyPipe.transform(standardGameReportP.GrossSales)}`),
      this.pdfUtilityService.generateLabelValueRowWithLargeLeft('Manual Sales: ', `${this.currencyPipe.transform(standardGameReportP.ManualSales)}`),
      this.pdfUtilityService.generateLabelValueRowWithLargeLeft('Autoplay Sales: ', `${this.currencyPipe.transform(standardGameReportP.AutoplaySales)}`)
    ]);

    columnOne.push(this.pdfUtilityService.generateLabelValueRow('Pot Total: ', `${this.currencyPipe.transform(standardGameReportP.PotTotal)}`));

    potDetails.push({
      columns: [
        columnOne
      ]
    });
    return potDetails;
  }

  private getFeeDetails(standardGameReportP: IStandardGameReport): Content[] {
    const causableDetails: Content[] = [];
    causableDetails.push(this.pdfUtilityService.generateSectionTitle('Fee Breakdown'));
    const columnOne = [
      this.pdfUtilityService.generateLabelValueRow('Causable Fees: ', `${this.currencyPipe.transform(standardGameReportP.TotalCausableFees)}`),
      this.pdfUtilityService.generateLabelValueRow('HST on Causable Fees: ', `${this.currencyPipe.transform(standardGameReportP.HstOnCausableFees)}`),
      this.pdfUtilityService.generateLabelValueRow('Stripe Fees: ', `${this.currencyPipe.transform(standardGameReportP.TotalStripeFees)}`),
      this.pdfUtilityService.generateLabelValueRow('Charity Host Admin Fees : ', `${this.currencyPipe.transform(standardGameReportP.TotalAdministrationCommissionFees)}`),
      this.pdfUtilityService.generateLabelValueRow('Total Fees : ', `${this.currencyPipe.transform(this.totalFees)}`),
    ];


    causableDetails.push({
      columns: [
        columnOne
      ]
    });
    return causableDetails;
  }
}
