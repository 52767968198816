<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Suspend Player</span>

  <ng-container dialog-content *ngIf="uiState === uiStateEnumForTemplate.ShowData">
    <form [formGroup]="suspendPlayersForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Reason for Suspension</mat-label>
        <mat-select formControlName="suspensionReason" data-testid="suspension-reason-select">
          @for (reason of reasonTypes; track reason) {
          <mat-option [value]="reason.value" data-testid="suspension-reason-option">
            {{reason.name}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (!isCausableSuspensionDialog && userIsCausableAdmin) {
        <mat-checkbox formControlName="isSuspendedFromCausable">
          Suspend Player From Causable
        </mat-checkbox>
      }
    </form>
  </ng-container>

  <ng-container actions>
    <button mat-button
            data-testid="confirm-suspend-player-button"
            (click)="suspendPlayer()">
      Suspend
    </button>
  </ng-container>
</app-dialog-base>
