import {Component, Inject} from '@angular/core';
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ISupportRequest} from "../../../interfaces/player/ISupportRequest";
import {
  UserHasCausableAdminAccessDirective
} from "../../../directives/permissions/user-has-causable-admin-access.directive";
import {MatIcon} from "@angular/material/icon";
import {PermissionsService} from "../../../services/permissions.service";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {MatButton, MatIconButton} from "@angular/material/button";
import {SupportRequestDialogComponent} from "../support-request-dialog/support-request-dialog.component";
import {SupportDeskColDefs} from "../../../pages/support-desk/support-desk-column-defs";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {SnackbarService} from "../../../services/snackbar.service";

@Component({
  selector: 'app-support-request-details',
  standalone: true,
  imports: [
    DialogBaseComponent,
    UserHasCausableAdminAccessDirective,
    MatIcon,
    MatButton,
    MatIconButton,
    CdkCopyToClipboard
  ],
  templateUrl: './support-request-details.component.html',
  styleUrl: './support-request-details.component.scss'
})
export class SupportRequestDetailsComponent {

  public uiState = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public userHasPermissionToModifySupportRequests = this.permissionsService.userHasPermission(PermissionTypes.ModifySupportRequests);

  constructor(@Inject(MAT_DIALOG_DATA) public supportRequest: ISupportRequest,
              private permissionsService: PermissionsService,
              private dialogRef: MatDialogRef<SupportRequestDetailsComponent>,
              private matDialog: MatDialog,
              private supportDeskColDefs: SupportDeskColDefs,
              private snackBarService: SnackbarService) {
  }

  openAssignAndResolveDialog() {
    this.matDialog.open(SupportRequestDialogComponent, {
      data: this.supportRequest,
      width: "650px"
    }).afterClosed().subscribe(
      {
        next: () => {
          this.dialogRef.close();
        }
      }
    )
  }

  displayErrorType(errorTypeP: number) {
    return this.supportDeskColDefs.errorTypes[errorTypeP - 1].name;
  }

  copyToClipBoard() {
    this.snackBarService.openSuccessfulSnackBar("Copied to clipboard!");
  }
}
