import {Component, Inject} from '@angular/core';
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {PlayersService} from "../../../services/players.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatButton} from "@angular/material/button";
import {ISuspendPlayer} from "../../../interfaces/player/ISuspendPlayer";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IGamePlayerInputData} from "../../../interfaces/player/IGamePlayerInputData";
import {MatCheckbox} from "@angular/material/checkbox";
import {ISuspendPlayerFromCausable} from "../../../interfaces/player/ISuspendPlayerFromCausable";
import {Observable} from "rxjs";

@Component({
  selector: 'app-suspend-player',
  standalone: true,
  imports: [
    DialogBaseComponent,
    NgIf,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatButton,
    MatLabel,
    MatCheckbox
  ],
  templateUrl: './suspend-player.component.html',
  styleUrl: './suspend-player.component.css'
})
export class SuspendPlayerComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public reasonTypes = [{name: "Payment Dispute", value: 1},
    {name: "Inappropriate Behavior", value: 2}, {name: "Suspicious Activity", value: 3}, {name: "Other", value: 4}]
  public userIsCausableAdmin = this.activeUserService.activeUser().CausableAdmin;
  public isCausableSuspensionDialog = this.gamePlayerData.game == null;

  public suspensionReasonControl = new FormControl<number | null>(null, Validators.required);
  public isSuspendedFromCausableControl = new FormControl<boolean>(false);

  public suspendPlayersForm = new FormGroup({
    suspensionReason: this.suspensionReasonControl,
    isSuspendedFromCausable: this.isSuspendedFromCausableControl
  });

  constructor(private playerService: PlayersService,
              private activeUserService: ActiveUserService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private matDialogRef: MatDialogRef<SuspendPlayerComponent>,
              @Inject(MAT_DIALOG_DATA) public gamePlayerData: IGamePlayerInputData) {
  }

  suspendPlayer() {
    if (!this.suspendPlayersForm.valid)
    {
      this.suspendPlayersForm.markAllAsTouched()
      return;
    }

    if (this.isCausableSuspensionDialog) {
      this.isSuspendedFromCausableControl.setValue(true);
    }

    this.uiState = UIStateEnum.ShowLoading;

    let suspend_player_call;

    if (this.isSuspendedFromCausableControl.value) {
      suspend_player_call = this.suspendPlayerFromCausable();
    } else {
      suspend_player_call = this.suspendPlayerFromGame();
    }

    suspend_player_call.subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openSuccessfulSnackBar("Successfully suspended player")
        this.matDialogRef.close(true);
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage("Failed to suspend player", err);
      }
    });
  }


  private suspendPlayerFromGame(): Observable<any> {
    let command: ISuspendPlayer = {
      AdminId: this.activeUserService.activeUser().Id,
      GameId: this.gamePlayerData.game.Id,
      PlayerId: this.gamePlayerData.playerId,
      ReasonType: this.suspensionReasonControl.value!
    }

    return this.playerService.suspendPlayer(command)
  }

  private suspendPlayerFromCausable(): Observable<any> {
    let command: ISuspendPlayerFromCausable = {
      PlayerId: this.gamePlayerData.playerId,
      AdminId: this.activeUserService.activeUser().Id,
      ReasonType: this.suspensionReasonControl.value!
    }

    return this.playerService.suspendPlayerFromCausable(command);
  }
}
