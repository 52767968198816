import {Component, Input, OnInit} from '@angular/core';
import {AgGridAngular} from "ag-grid-angular";
import {AsyncPipe} from "@angular/common";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {IPlayerSuspension} from "../../../interfaces/player/IPlayerSuspension";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {PlayerInfoTableDefs} from "../../player-game-info/player-info-table-defs";
import {PlayersService} from "../../../services/players.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialog} from "@angular/material/dialog";
import {PermissionsService} from "../../../services/permissions.service";
import {SuspendPlayerComponent} from "../../dialogs/suspend-player/suspend-player.component";
import {
  ResolvePlayerSuspensionComponent
} from "../../dialogs/resolve-player-suspension/resolve-player-suspension.component";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {IGamePlayerInputData} from "../../../interfaces/player/IGamePlayerInputData";
import {ActiveUserService} from "../../../services/active-user.service";

@Component({
  selector: 'app-manage-player-suspensions',
  standalone: true,
  imports: [
    AgGridAngular,
    HasPermissionDirective,
    MatButton,
    MatIcon,
    MatProgressSpinner
  ],
  templateUrl: './manage-player-suspensions.component.html',
  styleUrl: './manage-player-suspensions.component.css'
})
export class ManagePlayerSuspensionsComponent implements OnInit{

  @Input()
  public suspensions: IPlayerSuspension[] = [];

  @Input()
  public set gamePlayerData(gamePlayerDataP: IGamePlayerInputData) {
    if (gamePlayerDataP.playerId && gamePlayerDataP.game.Id) {
      this.game = gamePlayerDataP.game;
      this.playerId = gamePlayerDataP.playerId;
    }
  }

  @Input()
  public isCausableSuspensionTab: boolean = false;

  public playerSuspensionsColumnDefs: ColDef[] = this.playerInfoTables.playerSuspensionColumnDefs;
  public suspensionsApi!: GridApi<IPlayerSuspension>;
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;
  public game: IGameQueryResult | null = null;
  public playerId: string = '';

  protected readonly PermissionTypes = PermissionTypes;

  constructor(private playerInfoTables: PlayerInfoTableDefs,
              private playerService: PlayersService,
              private errorHandlingService: ErrorHandlingService,
              private matDialog: MatDialog,
              private permissionsService: PermissionsService,
              private activeUserService: ActiveUserService) {
  }

  ngOnInit() {
    if (!this.isCausableSuspensionTab) {
      this.playerSuspensionsColumnDefs = this.playerSuspensionsColumnDefs.filter(colDef => colDef.field !== "IsCausableSuspension");
    }
  }

  filterSuspensionsIfCausableAdminIsAccessingGameSuspensions() {
    if (this.activeUserService.activeUser().CausableAdmin && !this.isCausableSuspensionTab) {
      this.suspensions = this.suspensions.filter(suspension => suspension.GameId == this.game?.Id);
    }
  }

  openSuspendPlayerDialog() {
    if (!this.permissionsService.userHasPermission(PermissionTypes.ModifyPlayerSuspensions)) {
      return;
    }
    this.matDialog.open(SuspendPlayerComponent, {
      data: {
        game: this.isCausableSuspensionTab ? null : this.game,
        playerId: this.playerId
      },
      width: "650px"
    }).afterClosed().subscribe({
      next: (shouldSuspend) => {
        if (shouldSuspend) {
          this.playerService.fetchUpdatedSuspensionsSubject.next(true);
        }
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  onPlayerSuspensionsGridReady(params: GridReadyEvent<IPlayerSuspension>) {
    this.filterSuspensionsIfCausableAdminIsAccessingGameSuspensions();
    this.suspensionsApi = params.api;
    this.suspensionsApi.sizeColumnsToFit({
      defaultMinWidth: 100
    });
  }

  openPlayerSuspensionRowClick(chosenSuspension: any) {
    if (!this.permissionsService.userHasPermission(PermissionTypes.ModifyPlayerSuspensions)) {
      return;
    }

    this.matDialog.open(ResolvePlayerSuspensionComponent, {
      data: chosenSuspension.data,
      width: "650px"
    }).afterClosed().subscribe({
      next: (shouldResolve) => {
        if (shouldResolve) {
          this.playerService.fetchUpdatedSuspensionsSubject.next(true);
        }
      },
      error: err => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }
}
