<div class="one-column-page-container">
  <h2>Causable Admin Reporting</h2>
  @switch (chosenReportingView) {
    @case (null) {
      <c-container>
        <c-row class="gy-3">
          <c-col class="col-12 col-md-6">
            <div
              data-testid="date-range-reports-button"
              class="rounded-card-button"
              role="button"
              (click)="updateReportingScreen(DATE_RANGE_REPORTS)">
              <mat-icon>date_range</mat-icon>
              <h3>Multiple Draw Reports</h3>
            </div>
          </c-col>
          <c-col class="col-12 col-md-6">
            <div
              class="rounded-card-button"
              data-testid="per-game-reports-button"
              role="button"
              (click)="updateReportingScreen(REPORTING_PER_GAME)">
              <mat-icon>games</mat-icon>
              <h3>Single Draw Reports</h3>
            </div>
          </c-col>
          <c-col class="col-12 col-md-6">
            <div
              class="rounded-card-button"
              data-testid="charity-game-reports-button"
              role="button"
              (click)="updateReportingScreen(CHARITY_REPORT)">
              <mat-icon>volunteer_activism</mat-icon>
              <h3>Charity Report</h3>
            </div>
          </c-col>
        </c-row>
      </c-container>
    }
    @case (DATE_RANGE_REPORTS) {
      <div>
        <button class="mb-3" mat-raised-button (click)="updateReportingScreen(null)" data-testid="date-range-report-back-button">
          <mat-icon>arrow_back_ios</mat-icon>
          Back
        </button>
        <h3>Multiple Draw Reporting</h3>
        <app-date-range-reporting></app-date-range-reporting>
      </div>
    }
    @case (CHARITY_REPORT) {
      <div>
        <button class="mb-3" mat-raised-button (click)="updateReportingScreen(null)" data-testid="charity-support-report-back-button">
          <mat-icon>arrow_back_ios</mat-icon>
          Back
        </button>
        <h3>Charity Support Breakdown Report</h3>
        <app-game-charity-supporter-breakdown></app-game-charity-supporter-breakdown>
      </div>
    }
    @case (REPORTING_PER_GAME) {
      <div>
        <button class="mb-3" mat-raised-button (click)="updateReportingScreen(null)" data-testid="per-game-report-back-button">
          <mat-icon>arrow_back_ios</mat-icon>
          Back
        </button>
        <h3>Reporting by Draw</h3>
        <mat-stepper #stepper>
          <mat-step data-testid="per-game-report-step-one">
            <ng-template matStepLabel>Choose draw to generate reports</ng-template>
            <form>
              <app-choose-game-instance
                (chosenGameInstanceEvent)="updateChosenGameInstance($event, stepper)"></app-choose-game-instance>
            </form>
          </mat-step>
          <mat-step data-testid="per-game-report-step-two">
            <ng-template matStepLabel>View reports</ng-template>
            <button mat-raised-button (click)="resetActiveGameInstance(stepper)"
                    data-testid="per-game-report-view-report-back-button">
              <mat-icon>arrow_back_ios</mat-icon>
              Back
            </button>

            @if (chosenGameInstance) {
              <div class="margin-top-med">
                <ng-container>
                  <mat-tab-group [(selectedIndex)]="selectedTab" dynamicHeight disableRipple
                                 data-testid="report-tab-group">
                    @if (chosenGameInstance.State === gameInstanceStatusesForTemplate.WinnerAnnounced) {
                      <mat-tab label="Standard Game Report"
                               labelClass="standard-report-tab"
                               data-testid="per-game-report-standard-report-tab">
                        <div class="margin-top-large">
                          <app-standard-game-report
                            [updateChosenGameInstance]="chosenGameInstance"></app-standard-game-report>
                        </div>
                      </mat-tab>
                    }

                    <mat-tab label="Auditing Report"
                             labelClass="auditing-report-tab"
                             *hasPermission="permissionTypes.ViewAuditingReport"
                             data-testid="per-game-report-auditing-report-tab">
                      <div class="margin-top-large">
                        <app-auditing-report [updateChosenGameInstance]="chosenGameInstance"></app-auditing-report>
                      </div>
                    </mat-tab>
                    <mat-tab
                      labelClass="sales-per-game-report-tab"
                      label="Sales Per Game"
                      *hasPermission="permissionTypes.ViewGameSummaryReport"
                      data-testid="per-game-report-sales-per-game-tab">
                      <ng-template matTabContent>
                        <div class="margin-top-large">
                          <app-sales-per-game
                            [setDefaultGameInstanceDrawDate]="chosenGameInstance.EndedOn"></app-sales-per-game>
                        </div>
                      </ng-template>
                    </mat-tab>
                    <mat-tab
                      labelClass="ticket-sales-hourly-report-tab"
                      label="Hourly Ticket Sales"
                      *hasPermission="permissionTypes.ViewHourlyTicketSalesReport"
                      data-testid="per-game-report-hourly-sales-tab">
                      <div class="margin-top-large">
                        <app-hourly-ticket-sales
                          [setDefaultGameInstanceDrawDate]="chosenGameInstance.EndedOn"></app-hourly-ticket-sales>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </ng-container>
              </div>

            } @else {
              <div>
                No draw was chosen, please select a draw to view reports
              </div>
            }
          </mat-step>
        </mat-stepper>
      </div>
    }
  }

</div>
